.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F0FDFB;
    padding: 20px 40px;
    height: 100px;
}

.left-section {
    display: flex;
    align-items: center;
}

.logo {
    height: 50px;
    margin-right: 15px;
}

.text-link {
    color: #000;
    font-size: 16px;
    text-decoration: none;
}

.text-link:hover {
    text-decoration: underline;
}

.right-section {
    display: flex;
    /* align-items: center; */
    gap: 15px
}

.icon {
    color: #000;
    font-size: 24px;
    /* margin-left: 20px; */
    text-decoration: none;
}

.icon:hover {
    color: #1DA1F2; /* Example hover color */
}

@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        padding: 20px;
    }

    .left-section {
        margin-bottom: 20px;
    }

    .right-section {
        gap: 10px;
    }
}
