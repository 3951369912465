.section-style {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px 50px 100px 10px;
    background-color: #fff;
}

.content-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 30px;
}

.text-column {
    flex: 0 0 60%;
    padding-left: 30px;
    padding-right: 20px;
}

.text {
    font-size: 18px;
    line-height: 1.6;
    color: #333;
}

.link {
    color: #3EA2FF;
    text-decoration: none;
    font-weight: bold;
    margin-left:10px;
}

.link:hover {
    text-decoration: underline;
}

.image-column {
    flex: 0 0 40%;
    text-align: center;
}

.image {
    max-width: 100%;
    /* height: auto;
    border-radius: 10px; */
    max-width: 400px;
}

.search-box-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    gap: 10px;
    margin-top: 40px;
    position: relative;
    padding-bottom: 40px;
}

.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.dialog-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 80%;
    max-width: 500px;
}

.dialog-text {
    margin-bottom: 20px;
    font-size: 18px;
}

.dialog-button {
    display: inline-block;
    background-color: #3EA2FF;
    color: #fff;
    padding: 10px 20px;
    border-radius: 25px;
    text-decoration: none;
    margin-right: 10px;
}

.dialog-button:hover {
    background-color: #1E90FF;
}

.dialog-close-button {
    display: inline-block;
    background-color: #f44336;
    color: #fff;
    padding: 10px 20px;
    border-radius: 25px;
    text-decoration: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.dialog-close-button:hover {
    background-color: #d32f2f;
}


.search-input {
    flex: 1;
    padding: 15px;
    font-size: 16px;
    border-radius: 25px;
    border: 1px solid #ccc;
    outline: none;
}

.suggestion-list {
    list-style-type: none;
    padding: 0;
    margin: 5px 0 0 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    position: absolute;
    width: 100%; /* Full width of the input */
    z-index: 1;
    top: 40px; /* Positions below input field */
    color: #000;
}

.suggestion-item {
    padding: 10px;
    cursor: pointer;
}

.suggestion-item:hover {
    background-color: #f0f0f0;
}

.dropdown {
    padding: 15px;
    font-size: 16px;
    border-radius: 25px;
    border: 1px solid #ccc;
    outline: none;
    min-width: 180px;
}

.search-button {
    background-color: #3EA2FF;
    color: #fff;
    font-size: 16px;
    padding: 15px 25px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
}

.search-button:hover {
    background-color: #1E90FF;
}

.suggestion-list {
    list-style-type: none;
    padding: 0;
    margin: 5px 0 0 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    position: absolute;
    width: 100%; /* Full width of the input */
    z-index: 1;
    top: 40px; /* Positions below input field */
}

.button-container {
    text-align: right; /* Aligns the button to the right */
    margin-top: 20px;
    width: 80%;
}

.button {
    background-color: #3EA2FF;
    color: #fff;
    font-size: 16px;
    padding: 15px 25px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
}


@media (max-width: 768px) {
    .section-style {
        padding: 30px 10px 100px 10px
    }

    .content-row {
        flex-direction: column;
    }

    .text-column {
        flex: 1;        
        padding: 10px;
    }

    .image-column {
        flex: 1;
    }

    .search-box-container {
        width: 100%;
        display: grid;
    }

    .button-container {
        width: 100%;
        text-align: center;
    }
}