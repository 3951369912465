.header {
    position: relative;
    text-align: center;
    color: #000;
    background-image: url('../assets/header.png');
    background-size: cover;
    background-position: center;
    height: 850px;
    font-family: "Plus Jakarta Sans", sans-serif;
}

.logo {
    position: absolute;
    top: 20px;
    left: 20px;
    height: 60px;
}

.text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
}

.title {
    font-size: 42px;
    font-weight: 700;
    margin: 0 0 20px 0;
    line-height: 1.2;
}

.subtitle {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    line-height: 1.5;
    margin-bottom: 120px;
    margin-top: 40px;
}

.button-link {
    text-decoration: none;
}

.button {
    background-color: #3EA2FF;
    color: #fff;
    font-size: 16px;
    padding: 20px 30px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
}

.button:hover {
    background-color: #1E90FF;
}

@media (max-width: 768px) {
    .header {
        height: 100vh;
        padding: 10px;
        /* width: 100vw; */
    }

    .text-container {
        margin-top: 100px;
        padding: 10px;
        width: 80%;
    }

    .title {
        font-size: 2em;
    }

    .subtitle {
        font-size: 1em;
    }

    .button {
        font-size: 14px;
        padding: 8px 16px;
    }
}