.container-style {
    padding: 20px;
    text-align: center;
    max-width: 1200px;

}

.table-style {
    width: 80%;
    border-collapse: collapse;
    margin: 0 auto;
    min-width: 1200px;
    color: #000;
}

.header-style {
    background-color: #3EA2FF;
    color: #fff;
    padding: 10px;
}

.cell {
    padding: 10px;
    border: 1px solid #ddd;
}

@media (max-width: 768px) {
    .container-style {
        display: grid;
        width: 80%;
    }

    .table-style {
        width: 100%;
        min-width: auto;
    }
}